/**
 * Generated by orval v6.31.0 🍺
 * Do not edit manually.
 * UZone User API
 * # --- UZoneユーザ画面API仕様書 ---

## 各エンドポイントで発生するエラーの内容については、ページ下部のSchemaに記載しています

### ◇ 404, 500エラー
  | 基本的にすべてのエンドポイントで発生するエラーです。

### ◇ 401, 403エラー
  | ログインが必要な（鍵マークがついている）エンドポイントで発生するエラーです。

 * OpenAPI spec version: 0.0.1
 */
import { useMutation, useQuery } from "@tanstack/react-query";
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from "@tanstack/react-query";
import type {
  DeleteImage200,
  GetTmpImagePresignedUrl200,
  GetTmpImagePresignedUrlParams,
  GetUserMypageInfo200,
  PutImage200,
  PutImageBody,
  RegisterUserInfo200,
  RegisterUserInfoBody,
  UpdateEmail200,
  UpdateEmailBody,
  UpdateUserInfo200,
  UpdateUserInfoBody,
  UpdateUserInfoImage200,
  UpdateUserInfoImageBody,
  ValifyEmailUpdate200,
  ValifyEmailUpdateBody,
} from "../../../../schemas/prev/user";
import { customInstance } from "../../../../utils/CustomAxiosInstance";
import type { ErrorType, BodyType } from "../../../../utils/CustomAxiosInstance";

type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];

/**
 * マイページの情報を取得します
 * @summary マイページの情報を取得する
 */
export const getUserMypageInfo = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<GetUserMypageInfo200>({ url: `/user/mypage/info`, method: "GET" }, options);
};

export const getGetUserMypageInfoQueryKey = () => {
  return [`/user/mypage/info`] as const;
};

export const getGetUserMypageInfoQueryOptions = <
  TData = Awaited<ReturnType<typeof getUserMypageInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserMypageInfo>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetUserMypageInfoQueryKey();

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getUserMypageInfo>>> = () =>
    getUserMypageInfo(requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getUserMypageInfo>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetUserMypageInfoQueryResult = NonNullable<
  Awaited<ReturnType<typeof getUserMypageInfo>>
>;
export type GetUserMypageInfoQueryError = ErrorType<unknown>;

/**
 * @summary マイページの情報を取得する
 */
export const useGetUserMypageInfo = <
  TData = Awaited<ReturnType<typeof getUserMypageInfo>>,
  TError = ErrorType<unknown>,
>(options?: {
  query?: Partial<UseQueryOptions<Awaited<ReturnType<typeof getUserMypageInfo>>, TError, TData>>;
  request?: SecondParameter<typeof customInstance>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetUserMypageInfoQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * ユーザーが自身のアカウント情報を登録します
 * @summary アカウント情報を登録する
 */
export const registerUserInfo = (
  registerUserInfoBody: BodyType<RegisterUserInfoBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<RegisterUserInfo200>(
    {
      url: `/user/info/register`,
      method: "POST",
      headers: { "Content-Type": "application/json" },
      data: registerUserInfoBody,
    },
    options,
  );
};

export const getRegisterUserInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerUserInfo>>,
    TError,
    { data: BodyType<RegisterUserInfoBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof registerUserInfo>>,
  TError,
  { data: BodyType<RegisterUserInfoBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof registerUserInfo>>,
    { data: BodyType<RegisterUserInfoBody> }
  > = (props) => {
    const { data } = props ?? {};

    return registerUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type RegisterUserInfoMutationResult = NonNullable<
  Awaited<ReturnType<typeof registerUserInfo>>
>;
export type RegisterUserInfoMutationBody = BodyType<RegisterUserInfoBody>;
export type RegisterUserInfoMutationError = ErrorType<unknown>;

/**
 * @summary アカウント情報を登録する
 */
export const useRegisterUserInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof registerUserInfo>>,
    TError,
    { data: BodyType<RegisterUserInfoBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof registerUserInfo>>,
  TError,
  { data: BodyType<RegisterUserInfoBody> },
  TContext
> => {
  const mutationOptions = getRegisterUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * マイページの情報を更新します
 * @summary マイページの情報を更新する
 */
export const updateUserInfo = (
  updateUserInfoBody: BodyType<UpdateUserInfoBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpdateUserInfo200>(
    {
      url: `/user/info/update`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateUserInfoBody,
    },
    options,
  );
};

export const getUpdateUserInfoMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserInfo>>,
    TError,
    { data: BodyType<UpdateUserInfoBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserInfo>>,
  TError,
  { data: BodyType<UpdateUserInfoBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserInfo>>,
    { data: BodyType<UpdateUserInfoBody> }
  > = (props) => {
    const { data } = props ?? {};

    return updateUserInfo(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserInfoMutationResult = NonNullable<Awaited<ReturnType<typeof updateUserInfo>>>;
export type UpdateUserInfoMutationBody = BodyType<UpdateUserInfoBody>;
export type UpdateUserInfoMutationError = ErrorType<unknown>;

/**
 * @summary マイページの情報を更新する
 */
export const useUpdateUserInfo = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserInfo>>,
    TError,
    { data: BodyType<UpdateUserInfoBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserInfo>>,
  TError,
  { data: BodyType<UpdateUserInfoBody> },
  TContext
> => {
  const mutationOptions = getUpdateUserInfoMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * プロフィール画像を更新します
 * @summary プロフィール画像を更新する
 */
export const updateUserInfoImage = (
  updateUserInfoImageBody: BodyType<UpdateUserInfoImageBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpdateUserInfoImage200>(
    {
      url: `/user/info/update_image`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateUserInfoImageBody,
    },
    options,
  );
};

export const getUpdateUserInfoImageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserInfoImage>>,
    TError,
    { data: BodyType<UpdateUserInfoImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateUserInfoImage>>,
  TError,
  { data: BodyType<UpdateUserInfoImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateUserInfoImage>>,
    { data: BodyType<UpdateUserInfoImageBody> }
  > = (props) => {
    const { data } = props ?? {};

    return updateUserInfoImage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateUserInfoImageMutationResult = NonNullable<
  Awaited<ReturnType<typeof updateUserInfoImage>>
>;
export type UpdateUserInfoImageMutationBody = BodyType<UpdateUserInfoImageBody>;
export type UpdateUserInfoImageMutationError = ErrorType<unknown>;

/**
 * @summary プロフィール画像を更新する
 */
export const useUpdateUserInfoImage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateUserInfoImage>>,
    TError,
    { data: BodyType<UpdateUserInfoImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateUserInfoImage>>,
  TError,
  { data: BodyType<UpdateUserInfoImageBody> },
  TContext
> => {
  const mutationOptions = getUpdateUserInfoImageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * メールアドレスの変更をリクエストします
 * @summary メールアドレスを変更する
 */
export const updateEmail = (
  updateEmailBody: BodyType<UpdateEmailBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<UpdateEmail200>(
    {
      url: `/user/info/update_email`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: updateEmailBody,
    },
    options,
  );
};

export const getUpdateEmailMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEmail>>,
    TError,
    { data: BodyType<UpdateEmailBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof updateEmail>>,
  TError,
  { data: BodyType<UpdateEmailBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof updateEmail>>,
    { data: BodyType<UpdateEmailBody> }
  > = (props) => {
    const { data } = props ?? {};

    return updateEmail(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type UpdateEmailMutationResult = NonNullable<Awaited<ReturnType<typeof updateEmail>>>;
export type UpdateEmailMutationBody = BodyType<UpdateEmailBody>;
export type UpdateEmailMutationError = ErrorType<unknown>;

/**
 * @summary メールアドレスを変更する
 */
export const useUpdateEmail = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof updateEmail>>,
    TError,
    { data: BodyType<UpdateEmailBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof updateEmail>>,
  TError,
  { data: BodyType<UpdateEmailBody> },
  TContext
> => {
  const mutationOptions = getUpdateEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * コード認証によってメールアドレス変更を認証します
 * @summary メールアドレス変更を認証する
 */
export const valifyEmailUpdate = (
  valifyEmailUpdateBody: BodyType<ValifyEmailUpdateBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<ValifyEmailUpdate200>(
    {
      url: `/user/info/verification_email`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: valifyEmailUpdateBody,
    },
    options,
  );
};

export const getValifyEmailUpdateMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valifyEmailUpdate>>,
    TError,
    { data: BodyType<ValifyEmailUpdateBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof valifyEmailUpdate>>,
  TError,
  { data: BodyType<ValifyEmailUpdateBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof valifyEmailUpdate>>,
    { data: BodyType<ValifyEmailUpdateBody> }
  > = (props) => {
    const { data } = props ?? {};

    return valifyEmailUpdate(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type ValifyEmailUpdateMutationResult = NonNullable<
  Awaited<ReturnType<typeof valifyEmailUpdate>>
>;
export type ValifyEmailUpdateMutationBody = BodyType<ValifyEmailUpdateBody>;
export type ValifyEmailUpdateMutationError = ErrorType<unknown>;

/**
 * @summary メールアドレス変更を認証する
 */
export const useValifyEmailUpdate = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof valifyEmailUpdate>>,
    TError,
    { data: BodyType<ValifyEmailUpdateBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof valifyEmailUpdate>>,
  TError,
  { data: BodyType<ValifyEmailUpdateBody> },
  TContext
> => {
  const mutationOptions = getValifyEmailUpdateMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * tmpバケットの署名付きURLを取得する
 * @summary tmpバケットの署名付きURLを取得する
 */
export const getTmpImagePresignedUrl = (
  params: GetTmpImagePresignedUrlParams,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<GetTmpImagePresignedUrl200>(
    { url: `/user/users/images/url`, method: "GET", params },
    options,
  );
};

export const getGetTmpImagePresignedUrlQueryKey = (params: GetTmpImagePresignedUrlParams) => {
  return [`/user/users/images/url`, ...(params ? [params] : [])] as const;
};

export const getGetTmpImagePresignedUrlQueryOptions = <
  TData = Awaited<ReturnType<typeof getTmpImagePresignedUrl>>,
  TError = ErrorType<unknown>,
>(
  params: GetTmpImagePresignedUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTmpImagePresignedUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
) => {
  const { query: queryOptions, request: requestOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getGetTmpImagePresignedUrlQueryKey(params);

  const queryFn: QueryFunction<Awaited<ReturnType<typeof getTmpImagePresignedUrl>>> = () =>
    getTmpImagePresignedUrl(params, requestOptions);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<typeof getTmpImagePresignedUrl>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GetTmpImagePresignedUrlQueryResult = NonNullable<
  Awaited<ReturnType<typeof getTmpImagePresignedUrl>>
>;
export type GetTmpImagePresignedUrlQueryError = ErrorType<unknown>;

/**
 * @summary tmpバケットの署名付きURLを取得する
 */
export const useGetTmpImagePresignedUrl = <
  TData = Awaited<ReturnType<typeof getTmpImagePresignedUrl>>,
  TError = ErrorType<unknown>,
>(
  params: GetTmpImagePresignedUrlParams,
  options?: {
    query?: Partial<
      UseQueryOptions<Awaited<ReturnType<typeof getTmpImagePresignedUrl>>, TError, TData>
    >;
    request?: SecondParameter<typeof customInstance>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = getGetTmpImagePresignedUrlQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryOptions.queryKey;

  return query;
};

/**
 * 画像を登録・更新
 * @summary 画像を登録・更新
 */
export const putImage = (
  putImageBody: BodyType<PutImageBody>,
  options?: SecondParameter<typeof customInstance>,
) => {
  return customInstance<PutImage200>(
    {
      url: `/user/users/images`,
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      data: putImageBody,
    },
    options,
  );
};

export const getPutImageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putImage>>,
    TError,
    { data: BodyType<PutImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<
  Awaited<ReturnType<typeof putImage>>,
  TError,
  { data: BodyType<PutImageBody> },
  TContext
> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<
    Awaited<ReturnType<typeof putImage>>,
    { data: BodyType<PutImageBody> }
  > = (props) => {
    const { data } = props ?? {};

    return putImage(data, requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type PutImageMutationResult = NonNullable<Awaited<ReturnType<typeof putImage>>>;
export type PutImageMutationBody = BodyType<PutImageBody>;
export type PutImageMutationError = ErrorType<unknown>;

/**
 * @summary 画像を登録・更新
 */
export const usePutImage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<typeof putImage>>,
    TError,
    { data: BodyType<PutImageBody> },
    TContext
  >;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<
  Awaited<ReturnType<typeof putImage>>,
  TError,
  { data: BodyType<PutImageBody> },
  TContext
> => {
  const mutationOptions = getPutImageMutationOptions(options);

  return useMutation(mutationOptions);
};
/**
 * 画像を削除
 * @summary 画像を削除
 */
export const deleteImage = (options?: SecondParameter<typeof customInstance>) => {
  return customInstance<DeleteImage200>({ url: `/user/users/images`, method: "DELETE" }, options);
};

export const getDeleteImageMutationOptions = <
  TError = ErrorType<unknown>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteImage>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationOptions<Awaited<ReturnType<typeof deleteImage>>, TError, void, TContext> => {
  const { mutation: mutationOptions, request: requestOptions } = options ?? {};

  const mutationFn: MutationFunction<Awaited<ReturnType<typeof deleteImage>>, void> = () => {
    return deleteImage(requestOptions);
  };

  return { mutationFn, ...mutationOptions };
};

export type DeleteImageMutationResult = NonNullable<Awaited<ReturnType<typeof deleteImage>>>;

export type DeleteImageMutationError = ErrorType<unknown>;

/**
 * @summary 画像を削除
 */
export const useDeleteImage = <TError = ErrorType<unknown>, TContext = unknown>(options?: {
  mutation?: UseMutationOptions<Awaited<ReturnType<typeof deleteImage>>, TError, void, TContext>;
  request?: SecondParameter<typeof customInstance>;
}): UseMutationResult<Awaited<ReturnType<typeof deleteImage>>, TError, void, TContext> => {
  const mutationOptions = getDeleteImageMutationOptions(options);

  return useMutation(mutationOptions);
};
