import Axios, {
  AxiosRequestConfig,
  InternalAxiosRequestConfig,
  AxiosResponse,
  AxiosError,
} from "axios";
import _ from "lodash";
import { getCookie, deleteCookie } from "./cookieUtils";

export const AXIOS_INSTANCE = Axios.create({
  baseURL: process.env.REACT_APP_API_END_POINT,
  headers: {
    "Content-type": "application/json",
    locale: "ja",
  },
});

// Add request interceptor
AXIOS_INSTANCE.interceptors.request.use(
  (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
    /** SET HEADERS AUTH HERE */
    const accessToken = getCookie("access_token");
    if (accessToken) {
      if (config.headers) {
        config.headers.Authorization = `Bearer ${accessToken}`;
      }
    }
    return config;
  },
  (error: AxiosError): Promise<never> => {
    return Promise.reject(error);
  },
);

// Add response interceptor
AXIOS_INSTANCE.interceptors.response.use(
  (response: AxiosResponse): AxiosResponse => {
    /** Handle SPECIFIC RESPONSE HERE */
    return response;
  },
  (error: AxiosError): Promise<never> => {
    /** HANDLE FAILED CASE HERE */
    if (
      _.get(error, "response.status", 0) === 401 &&
      window.location.pathname.indexOf("product") !== 1
    ) {
      clearSession();
    }
    return Promise.reject(error);
  },
);

// Custom instance function
export const customInstance = <T>(
  config: AxiosRequestConfig,
  options?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const source = Axios.CancelToken.source();
  const promise = AXIOS_INSTANCE({
    ...config,
    ...options,
    cancelToken: source.token,
  });

  // @ts-ignore
  promise.cancel = () => {
    source.cancel("Query was cancelled");
  };

  return promise;
};

// Error type for custom instance
export type ErrorType<Error> = AxiosError<Error>;

// Body type for custom instance
export type BodyType<BodyData> = BodyData;

// Function to clear session
const clearSession = (): void => {
  deleteCookie("access_token");
  localStorage.removeItem("state");
  if (window.location.pathname !== "/auth/login") {
    window.location.href = "/auth/login";
  }
};
