import { useGetUserMypageInfo } from "@/apis/prev/user/user/user";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { getCookie } from "@/utils/cookieUtils";
import ENVS from "@/constants/envs";
import { useLocation } from "react-router-dom";

const GtmProvider = ({ children }) => {
  const isLoggedIn = !!getCookie("access_token");
  const gtmId = ENVS.GTM_KEY;
  const location = useLocation();
  const [currentFullPath, setCurrentFullPath] = useState(`${location.pathname}${location.search}`);

  const { data, isSuccess } = useGetUserMypageInfo({
    query: {
      enabled: isLoggedIn,
    },
  });

  useEffect(() => {
    // gtmIdが空でない場合にのみ、GTM 初期化（初回レンダリング時のみ実行）
    if (gtmId) {
      TagManager.initialize({ gtmId });
    }
  }, [gtmId]);

  useEffect(() => {
    if (isSuccess && data) {
      window.dataLayer = window.dataLayer || [];
      // ログイン時はユーザIDを追加
      window.dataLayer.push({
        user_id: data.data.data.user.user_id,
      });
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (location.pathname == "/request" && !currentFullPath.includes("/request")) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: "visited_inquiry_form",
        event_scope: {
          path_from: currentFullPath,
        },
      });
    }
    setCurrentFullPath(`${location.pathname}${location.search}`);
  }, [location.pathname, location.search]);

  return children;
};

export default GtmProvider;
