import { put, takeLatest, call } from "redux-saga/effects";
import {
  FIND_PREFECTURE_SAGA,
  FORGOT_PASSWORD_REQUEST,
  GET_PREFECTURE_LIST,
  GET_PAYMENTDATE_LIST,
  PASSWORD_RESET_REQUEST,
  PREFECTURE_OBJECT,
  PUT_PREFECTURE_LIST,
  PUT_PAYMENTDATE_LIST,
  PUT_SIGN_UP_EMAIL,
  PUT_VERIFIED_SIGN_UP_EMAIL,
  SIGNUP_COMPANY_FORM,
  SIGNUP_EMAIL,
  SIGNUP_EMAIL_VERIFICATION,
  SIGNUP_USER_FORM,
} from "./constants";
import _ from "lodash";
import { toastr } from "react-redux-toastr";
import {
  COMPANT_FIND_ZIP_CODE_API,
  FORGOT_PASSWORD_API,
  PREFECTURE_LIST_API,
  PAYMENTDATE_LIST_API,
  RESER_PASSWORD_API,
  SIGNUP_EMAIL_API,
  SIGNUP_EMAIL_VERIFICATION_API,
  SIGNUP_FORM_API,
  SIGNUP_USER_FORM_API,
} from "@/utils/apiConstants";
import instance from "@/utils/axiosInstance";
import { API_FAIL } from "../Api/constants";
import { LOGIN_DZONE_MY_PAGE_NOT_LOGIN_REDUCER } from "../UserProfile/MyPageNoLogin/constants";
import { GET_INFO_DZONE_MY_PAGE_SAGA } from "../Admin/MyPage/MyPage/constants";
import { setCookie } from "@/utils/cookieUtils";

function signupEmailRegister(payload, isLoading) {
  isLoading(true);
  return instance
    .post(SIGNUP_EMAIL_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function signupEmailVerificationAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .post(SIGNUP_EMAIL_VERIFICATION_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function signupRegistrationFormAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .post(SIGNUP_FORM_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function findPrefectureAPI(payload, isLoading) {
  isLoading(true);
  return instance
    .get(COMPANT_FIND_ZIP_CODE_API, { params: payload })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function getPrefecturesListAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .get(PREFECTURE_LIST_API, { params: payload })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function getPaymentDateListAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .get(PAYMENTDATE_LIST_API, { params: payload })
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function signupRegistrationUserFormAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .post(SIGNUP_USER_FORM_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function forgotPasswordRequestAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .put(FORGOT_PASSWORD_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

function resetPasswordRequestAPICall(payload, isLoading) {
  isLoading(true);
  return instance
    .put(RESER_PASSWORD_API, payload)
    .then((response) => {
      isLoading(false);
      return _.get(response, "data", {});
    })
    .catch((error) => {
      isLoading(false);
      return {
        message: _.get(error, "response.data.message", {}),
        success: false,
      };
    });
}

const signupEmail = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let navigate = payload.payload.navigate;
  try {
    response = yield call(signupEmailRegister, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_SIGN_UP_EMAIL,
        payload: _.get(response, "data"),
      });
      navigate("/auth/signup_code", {
        state: { email: payload.payload.payload.email },
      });
    } else {
      yield put({
        type: API_FAIL,
        payload: {
          message: _.get(response, "message"),
        },
      });
    }
  } catch (error) {
    console.error("catch --->");
  }
};

const signupEmailVerification = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let navigate = payload.payload.navigate;
  let requestPayload = payload.payload.payload;
  try {
    response = yield call(signupEmailVerificationAPICall, requestPayload, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_VERIFIED_SIGN_UP_EMAIL,
        payload: _.get(requestPayload, "email", null),
      });
      navigate("/auth/signup_type", {
        state: { email: requestPayload.email },
      });
    } else {
      yield put({
        type: API_FAIL,
        payload: {
          message: _.get(response, "message"),
        },
      });
    }
  } catch (error) {
    console.error("catch --->");
  }
};

const signupRegistrationForm = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let navigate = payload.payload.navigate;
  const refetch = payload.payload.myPageInfoRefetch;
  const userType = parseInt(localStorage.getItem("userType"));
  localStorage.removeItem("userType");
  const userSelectedImageFile = payload.payload.image;
  try {
    response = yield call(signupRegistrationFormAPICall, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      setCookie("access_token", _.get(response, "data.access_token", {}));

      yield put({
        type: LOGIN_DZONE_MY_PAGE_NOT_LOGIN_REDUCER,
        payload: {
          access_token: _.get(response, "data.access_token"),
          type: _.get(response, "data.type"),
        },
      });
    } else {
      yield put({
        type: API_FAIL,
        payload: {
          message: _.get(response, "message"),
        },
      });
    }
  } catch (error) {
    console.error("catch --->", error);
  }
  try {
    yield put({
      type: GET_INFO_DZONE_MY_PAGE_SAGA,
      payload: {
        params: null,
        isLoading: isLoading,
        navigate,
      },
    });
  } catch (error) {
    console.error("catch --->", error);
  }

  window.dataLayer.push({
    event: "sign_up",
    user_type: userType ?? "",
    user_id: response.data.user_id ?? "",
  });

  //登録する画像がない場合は、登録完了画面へ遷移
  if (!userSelectedImageFile) {
    navigate("/auth/signup_complete");
  }

  refetch(); //signupConfirm.jsにて、myPageInfoをrefetchしてuseEffectを発火させたい
};

const findPrefecture = function* (payload) {
  let isLoading = payload.payload.isLoading;
  let response = yield findPrefectureAPI(payload.payload.pincode, isLoading);
  if (_.get(response, "result", false)) {
    yield put({
      type: PREFECTURE_OBJECT,
      payload: _.get(response, "data", {}),
    });
  } else {
    yield put({ type: PREFECTURE_OBJECT, payload: {} });
    toastr.error("", _.get(response, "message"));
  }
};

const getPrefecturesList = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  try {
    response = yield call(getPrefecturesListAPICall, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_PREFECTURE_LIST,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("", _.get(error, "message"));
  }
};

const getPaymentDateList = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  try {
    response = yield call(getPaymentDateListAPICall, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      yield put({
        type: PUT_PAYMENTDATE_LIST,
        payload: _.get(response, "data"),
      });
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("", _.get(error, "message"));
  }
};

const signupRegistrationUserForm = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let navigate = payload.payload.navigate;
  try {
    response = yield call(signupRegistrationUserFormAPICall, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      setCookie("access_token", _.get(response, "data.access_token", {}));

      yield put({
        type: LOGIN_DZONE_MY_PAGE_NOT_LOGIN_REDUCER,
        payload: {
          access_token: _.get(response, "data.access_token"),
          type: _.get(response, "data.type"),
        },
      });
      navigate("/auth/signup_complete");
    } else {
      toastr.error("", _.get(response, "message"));
    }
  } catch (error) {
    toastr.error("", _.get(error, "message"));
  }
};

const forgotPasswordRequest = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  try {
    response = yield call(forgotPasswordRequestAPICall, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
      //ログイン画面へ遷移
      payload.payload.navigate("/auth/login");
    } else {
      yield put({
        type: API_FAIL,
        payload: {
          message: _.get(response, "message"),
        },
      });
    }
  } catch (error) {
    console.error("catch --->");
  }
};

const resetPasswordRequest = function* (payload) {
  let response;
  let isLoading = payload.payload.isLoading;
  let navigate = payload.payload.navigate;
  try {
    response = yield call(resetPasswordRequestAPICall, payload.payload.payload, isLoading);
    if (_.get(response, "result", false)) {
      toastr.success("", _.get(response, "message"));
      navigate("/auth/login");
    } else {
      yield put({
        type: API_FAIL,
        payload: {
          message: _.get(response, "message"),
        },
      });
    }
  } catch (error) {
    console.error("catch --->");
  }
};

function* signSaga() {
  yield takeLatest(SIGNUP_EMAIL, signupEmail);
  yield takeLatest(SIGNUP_EMAIL_VERIFICATION, signupEmailVerification);
  yield takeLatest(SIGNUP_COMPANY_FORM, signupRegistrationForm);
  yield takeLatest(FIND_PREFECTURE_SAGA, findPrefecture);
  yield takeLatest(GET_PREFECTURE_LIST, getPrefecturesList);
  yield takeLatest(GET_PAYMENTDATE_LIST, getPaymentDateList);
  yield takeLatest(SIGNUP_USER_FORM, signupRegistrationUserForm);
  yield takeLatest(FORGOT_PASSWORD_REQUEST, forgotPasswordRequest);
  yield takeLatest(PASSWORD_RESET_REQUEST, resetPasswordRequest);
}

export default signSaga;
